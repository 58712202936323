
/**
 *
 * Created by Filipe - 24/09/2022
 *
 */
import {computed, reactive} from "vue";
import {useStore} from "@/store";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import 'bootstrap/dist/js/bootstrap.bundle.min';

export default {
    name: "NavbarMenu",

    setup() {

        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const state = reactive({
            isUserAdmin: computed<boolean>(() => store.getters.getLoggedUser === null ? false : store.getters.getLoggedUser.profile.description === 'admin'),
            showMenuItems: computed<boolean>(() => route.name !== 'login'),
        })

        const logoutHandler = () => {
            axios.post('/logout')
            window.sessionStorage.clear();
            window.location.assign('/login')
        }

        const restartHandler = () => {
            window.location.assign('/');
        }
        return {
            route,
            router,
            state,
            logoutHandler,
            restartHandler
        }
    }
}
