import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df5f6e82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-main-container" }
const _hoisted_2 = { class: "alert-heading text-left" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "slide-up",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesList, (alert) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["alert", {'alert-success' : alert.type === 'success', 'alert-danger' : alert.type === 'error', 'alert-warning' : alert.type === 'warning', 'alert-info' : alert.type === 'info'}]),
            role: "alert",
            key: alert.id
          }, [
            _createElementVNode("h4", _hoisted_2, _toDisplayString(alert.title), 1),
            _createElementVNode("p", {
              class: "text-left",
              innerHTML: alert.message
            }, null, 8, _hoisted_3),
            _withDirectives(_createElementVNode("hr", null, null, 512), [
              [_vShow, !!alert.helpText]
            ]),
            _withDirectives(_createElementVNode("p", {
              class: "text-left mb-0",
              innerHTML: alert.helpText
            }, null, 8, _hoisted_4), [
              [_vShow, !!alert.helpText]
            ])
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}