
/**
 *
 * Created by Filipe - 11/01/2021
 *
 */
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "@/store";

import Modal from 'bootstrap/js/dist/modal'

/**
 *
 * - AVAILABLE TYPES:
 *  success - green header
 *  danger - red header
 *  primary - blue header
 *  warn - yellow header
 *  info - info header
 *
 */
export default {
    name: "AckPopup",

    setup() {
        const store = useStore()
        const modalElement = ref(null)

        const state = computed(() => store.getters.getAcknowledgeData)

        let myModal: Modal;

        const styleState = reactive({
            headerTitleStyle: computed(() => {
                const type = store.getters.getAcknowledgeData.type
                switch (type) {
                    case 'success':
                        return 'text-white bg-success'
                    case 'danger':
                        return 'text-white bg-danger'
                    case 'primary':
                        return 'text-white bg-primary'
                    case 'warn':
                        return 'text-white bg-warning'
                    case 'info':
                        return 'text-white bg-info'
                }
                return ''
            }),
            btnStyle: computed(() => {
                let btnStyle = store.getters.getAcknowledgeData.btnStyle
                if (!btnStyle) {
                    const type = store.getters.getAcknowledgeData.type
                    switch (type) {
                        case 'danger':
                            btnStyle = 'btn-danger'
                            break
                        case 'info':
                            btnStyle = 'btn-info'
                            break
                        case 'success':
                            btnStyle = 'btn-success'
                            break
                        case 'warn':
                            btnStyle = 'btn-warn'
                            break
                        default:
                            btnStyle = 'btn-primary-custom'
                            break
                    }
                }
                return btnStyle
            }),
        })

        onMounted(() => {
            myModal = new Modal('#the_modal', {
                keyboard: false
            })
            const element = document.getElementById('the_modal');
            if (element) {
                element.addEventListener('hidden.bs.modal', () => {
                    store.commit('showAcknowledge', false)
                })
            }

        })

        watch(
            () => state.value.show,
            (show/*, prevShow*/) => {
                show ? myModal.show() : myModal.hide()
            }
        )

        function closeModal() {
            // console.debug('closeModal')
            store.commit('showAcknowledge', false)
        }


        return {
            modalElement,
            state,
            styleState,
            closeModal,
        }


    }
}
