import {DocumentsFilterData} from "@/utils/common";
import {Page} from "@/interfaces/common";

interface State {
    filterData: DocumentsFilterData | null,
    documentsPage: Page | null
}

const state: State = {
    filterData: null,
    documentsPage: null,
}

export default {
    state,
    getters: {
        getDocumentsFilter: (state: State): DocumentsFilterData | null => state.filterData,
        getDocumentsPage: (state: State): Page | null => state.documentsPage,
    },
    mutations: {
        setDocumentsFilter(state: State, data: DocumentsFilterData) {
            state.filterData = data;
        },
        setDocumentsPage(state: State, data: Page) {
            state.documentsPage = data;
        },

    },
    actions: {},
    namespaced: true,
}
