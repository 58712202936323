import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/main.css';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faArrowLeft,
    faBook,
    faCheck,
    faGears,
    faPenToSquare,
    faPlus,
    faPowerOff,
    faSeedling,
    faSpinner,
    faTrashCan,
    faUser,
    faUsers,
    faXmark,
    faTrash,
    faChevronLeft,
    faChevronRight,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons'
import {faFloppyDisk} from '@fortawesome/free-regular-svg-icons'

library.add(faSpinner, faPlus, faArrowLeft, faUsers, faChevronLeft, faChevronRight, faTrash, faFloppyDisk, faTrashCan, faXmark, faRightFromBracket, faPenToSquare, faPowerOff, faSeedling, faUser, faGears, faBook, faCheck)


createApp(App)
    .use(store, key)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
